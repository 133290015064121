import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Chip, Container, Grid } from '@material-ui/core';
import TopBar from './components/TopBar';
import GameCard from './components/GameCard';
import { Battery50, Battery80, DirectionsRun, EvStation, Filter8, Functions, GpsFixed, MonetizationOn, SportsEsports } from '@material-ui/icons';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      
      &copy; pope.games{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function App() {

  return (
    <>
      <TopBar />
      <Container maxWidth="xl">
        <Box mt={4} mb={4}>
          <Grid container spacing={3} alignContent="center" justify="center">
            <Grid item>
              <GameCard title="Munchies" subtitle="Factoring and multiples" image="/images/munchies.png" logo="/images/munchies_logo.png" play="https://munchies.pope.games/" icon={<Functions />} summary={<>
                  Play with up to 8 characters, compete for high scores and collect pickups to increase your life. Be quick! The munchie monsters get stronger the longer you play!
                </>}
                tags={
                  <>
                    <Chip
                      size="small"
                      icon={<MonetizationOn />}
                      label="ads"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<Functions aria-label="educational" />}
                      label="math"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<SportsEsports />}
                      label="gamepad"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<Filter8 aria-label="8" />}
                      label="hot-seat"
                    />
                  </>
                }
              />
            </Grid>

            <Grid item>
              <GameCard title="Parkour Champions" subtitle="Jump and run" image="/images/parkour-champions.png" logo="/images/parkour-champions_logo.png" download="/downloads/parkour-champions.zip" downloadPlatform="Windows 10" icon={<DirectionsRun />} summary={<>
                    Start at the beginning of the course, jump your way to the finish. Reach checkpoints to save your progress for that run. Game is in early-prototyping phase. If you play and like it, let me know!
                  </>}
                tags={
                  <>
                    <Chip
                      size="small"
                      icon={<Battery50 />}
                      label="alpha"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<EvStation />}
                      label="mini-game"
                    />
                  </>
                }
              />
            </Grid>

            <Grid item>
              <GameCard title="TD" subtitle="Upgrade and scream!" image="/images/td.png" logo="/images/td_logo.png" play="https://td.pope.games/" icon={<GpsFixed />} summary={<>
                  Upgrade your towers and you'll get stronger ones and more monsters will spawn. Beat wave 6 to win. There are 2 types of towers that can be upgraded to tier 7.
                </>}
                tags={
                  <>
                    <Chip
                      size="small"
                      icon={<Battery80 />}
                      label="beta"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<EvStation />}
                      label="mini-game"
                    />
                    {' '}
                    <Chip
                      size="small"
                      icon={<GpsFixed />}
                      label="tower-defense"
                    />
                    
                  </>
                }
              />
            </Grid>
            
          </Grid>
        </Box>
        <Copyright />
      </Container>
    </>
  );
}