import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Box, Button, Link } from '@material-ui/core';
import { Functions } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main[500],
  },
}));

export default function GameCard({title, subtitle, logo, icon, url, play, download, downloadPlatform, image, authors, tags, price, summary, children, ...props}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} {...props}>
      <Link aria-label="play" href={url ? url : play}>
        <CardHeader
            avatar={
            <Avatar aria-hidden={true} className={classes.avatar}>
                <img src={logo} alt="" />
            </Avatar>
            }
            action={
            <IconButton aria-label="settings">
              {icon}
            </IconButton>
            }
            title={title}
            subheader={subtitle}
        />
        <CardMedia
            className={classes.media}
            image={image}
            title=""
        >
            
        </CardMedia>
      </Link>
      <CardContent>
        <Box mb={3}>
            <Typography variant="body2" color="textSecondary" component="p">
                {tags}
            </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          {summary}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {play ? (
            <Button href={play} startIcon={<PlayIcon />} variant="contained" color="primary">
                Play
            </Button>
        ) : ( '' )}

        {download ? (
            <Button startIcon={<DownloadIcon />} variant="contained" color="primary" aria-label="download" href={download}>
                {downloadPlatform}
            </Button>
        ) : ( '' )}

        {children ? (
            <IconButton
            className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            >
            <ExpandMoreIcon />
            </IconButton>
        ) : ( '' )}
      </CardActions>
      {children ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
            {children}
            </CardContent>
        </Collapse>
        ) : ( '' )}
    </Card>
  );
}